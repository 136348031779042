import { Controller } from 'stimulus'
import '../helpers/clipboard'

export default class extends Controller {
  copy(event) {
    event.preventDefault()

    try {
      window.Clipboard.copy(this.invitationUrl)
      alert('Davet adresi kopyalandı', 'success')
    } catch (e) {
      console.log(e)
    }
  }

  get invitationUrl() {
    return this.data.get('invitationUrl')
  }
}
