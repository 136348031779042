import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['totalAmount', 'amount']

  connect() {
    this.totalAmountTarget.textContent = this.amount
  }

  stepUp(event) {
    this.totalAmount = this.amountTargets.map(t => parseFloat(t.value)).reduce((a,b) => a + b, 0)
    this.totalAmountTarget.textContent = this.totalAmount
    this.preview(event)
  }

  stepDown(event) {
    this.totalAmount = this.amountTargets.map(t => parseFloat(t.value)).reduce((a,b) => a + b, 0)
    this.totalAmountTarget.textContent = this.totalAmount
    this.preview(event)
  }

  toggle(event) {
    this.amountTargets.map(amount => amount.value = 0)
  }

  preview(event) {
    if(this.orderSummaryController !== undefined) {
      this.orderSummaryController.preview(event)
    }
  }

  // Getters
  get minimum() {
    return parseFloat(this.data.get('minimum'))
  }

  get maximum() {
    return this.data.get('maximum')
  }

  get unit() {
    return this.data.get('unit')
  }

  get required() {
    return this.data.get('required')
  }

  get amount() {
    return parseFloat(this.data.get('amount'))
  }

  get orderSummaryController() {
    return this.application.controllers.find(c => c.context.identifier === 'checkout--order-summary')
  }

  // Setters
  set amount(value) {
    this.data.set('amount', value)
    this.totalAmountTarget.textContent = value
  }
}
