import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content']
  // 'preliminaryInformation', 'salesContract'

  // showModal(event) {
  //   var type = event.currentTarget.dataset.type
  //   var content = this[`${type}Target`].innerHTML
  //
  //   modal(content)
  //
  //   event.preventDefault()
  // }
  open = false

  initialize() {
    if (this.show === 'true') {
      this.open = true
    }
  }

  connect() {
    if (this.open) {
      this.openModal()
    } else {
      this.closeModal()
    }
  }

  openModal() {
    var content = this.contentTarget.innerHTML

    modal(content)

    event.preventDefault()
  }

  closeModal() {}

  get show() {
    return this.data.get('show')
  }
}
