import { Controller } from 'stimulus'

export default class extends Controller {
  // initialize() {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const paymentMethodId = urlParams.get('payment_method_id');

  //   if(paymentMethodId === this.data.get('id')) {
  //     document.querySelector(`div[data-payment-method-id="${paymentMethodId}"] a[data-method="put"]`).click()
  //   }
  // }
}
