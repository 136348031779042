import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['showAddressFormsTrigger', 'hideAddressFormsTrigger', 'selectors', 'forms',
                    'billingAddressForm', 'shippingAddressSelector', 'billingAddressSelector',
                    'showAddressFormsTrigger', 'hideAddressFormsTrigger',
                    'useAsBilling', 'invoiceType', 'invoiceForm', 'showAddressForms', 'invoiceTypeSelector']

  connect() {
    this.checkAddressForms()
    this.checkUseAsBilling()
    // this.checkInvoiceType()
  }

  toggleAddressForms() {
    this.showAddressForms = !(this.showAddressForms == 'true')
    this.checkAddressForms()
  }

  checkUseAsBilling() {
    if (this.useAsBillingTarget.checked) {
      this.billingAddressFormTarget.classList.add('hidden')
    } else {
      this.billingAddressFormTarget.classList.remove('hidden')
    }
  }

  changeAddressType() {
    if (this.useAsBillingTarget.checked) {
      this.invoiceTypeSelectorTarget.classList.remove('hidden')
    } else {
      this.invoiceTypeSelectorTarget.classList.add('hidden')
    }

    this.changeInvoiceType()
  }

  checkAddressForms() {
    if (this.showAddressForms == 'false') {
      this.selectorsTarget.classList.remove('hidden')
      this.formsTarget.classList.add('hidden')

      this.showAddressFormsTriggerTarget.classList.remove('hidden')
      this.hideAddressFormsTriggerTarget.classList.add('hidden')
    } else {
      this.selectorsTarget.classList.add('hidden')
      this.formsTarget.classList.remove('hidden')

      this.showAddressFormsTriggerTarget.classList.add('hidden')
      this.hideAddressFormsTriggerTarget.classList.remove('hidden')
    }
  }

  changeInvoiceType() {
    if (this.invoiceTypeTargets.filter(v => v.checked == true)[0].value === 'personal') {
      this.invoiceFormTarget.classList.add('hidden')
    } else {
      this.invoiceFormTarget.classList.remove('hidden')
    }
  }

  get showAddressForms() {
    return this.data.get('showAddressForms')
  }

  set showAddressForms(value) {
    this.data.set('showAddressForms', value)
    this.showAddressFormsTarget.value = value
  }
}
