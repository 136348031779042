import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['result', 'plan']

  // connect() {
  //   console.log('Plans controller connected')
  // }

  // getControllerByIdentifier(identifier) {
  //   return this.application.controllers.find(controller => {
  //     return controller.context.identifier === identifier;
  //   });
  // }

  setPlan(event) {
    var planId = null

    if (event.currentTarget.dataset.planId !== undefined) {
      this.removeActiveClass()
      this.addActiveClass(event.currentTarget)
      planId = event.currentTarget.dataset.planId
    } else {
      planId = event.currentTarget.value
    }

    this.resultTarget.value = planId

    this.preview()
    // this.setNextDatesForCheckout(event)
  }

  setNextDatesForCheckout(event) {
    var pattern = /subscriptions\/(\d+)/
    var result = pattern.exec(window.location.href)

    if (result !== null) {
      var subscriptionId = result[1]
      var planId = event.currentTarget.dataset.planId

      fetch(`/subscriptions/${subscriptionId}/next_dates_for_checkout?v2_subscription_plan_id=${this.resultTarget.value}`)
        .then(response => response.text())
        .then(result => {
          this.nextDatesForCheckoutTarget.outerHTML = result
        })
    }
  }

  removeActiveClass() {
    this.planTargets.map(p => p.classList.remove('active'))
  }

  addActiveClass(target) {
    target.classList.add('active')
  }

  preview(event) {
    this.orderSummaryController.preview(event)
  }

  get orderSummaryController() {
    return this.application.controllers.find(c => c.context.identifier === 'checkout--order-summary')
  }
}
