import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['price', 'arrow', 'collapsed']

  preview(event) {
    if(this.form !== null) {
      this.form.action = `/checkout/preview/${this.productId}`;
      // this.submitForm();
      Rails.fire(this.form, 'submit')
    }
  }

  submit(event) {
    if (this.formController[0].valid) {
      this.form.action = `/checkout/save_product`;
      window.history.pushState({}, 'Product', window.location.href);
      // this.submitForm();
      // Rails.fire(this.form, 'submit')
    }
  }

  submitAddressForm(event) {
    if (this.formController[0].valid) {
      window.history.pushState({}, 'Adresses', window.location.href);
      // this.submitForm();
      // Rails.fire(this.form, 'submit')
    }
  }

  save(event) {
    if (this.formController[0].valid) {
      this.form.action = `/subscriptions/${event.currentTarget.dataset.subscriptionId}`;
      // this.submitForm();
      Rails.fire(this.form, 'submit')
    }
  }

  checkout(event) {
    if (this.formController[0].valid) {
      this.form.action = `/subscriptions/${event.currentTarget.dataset.subscriptionId}/checkout`;
      // this.submitForm();
      Rails.fire(this.form, 'submit')
    }
  }

  toggleCollapse(event) {
    var heightClass = 'h-32'
    event.currentTarget.children[0].classList.add('hidden')
    event.currentTarget.children[1].classList.add('hidden')

    if (this.element.classList.contains(heightClass)) {
      this.element.classList.remove(heightClass)
      this.collapsedTarget.classList.add('hidden')
      event.currentTarget.children[0].classList.remove('hidden')
    } else {
      this.element.classList.add(heightClass)
      this.collapsedTarget.classList.remove('hidden')
      event.currentTarget.children[1].classList.remove('hidden')

      window.dataLayer.push({
        'event': 'clickEvents',
        'eventCategory': 'Click Events',
        'eventAction': 'Order Summary',
        'eventLabel': 'Click'
      });
    }
  }

  // submitForm() {
  //   const e = new CustomEvent('submit', { bubbles: true });
  //   this.form.dispatchEvent(e);
  // }

  get formController() {
    return this.application.controllers.filter(c => c.identifier === 'form')
  }

  get form() {
    let el = document.querySelector('#checkout > form')

    if (el === null) {
      el = document.querySelector('#addresses > form')
    }

    return el
  }

  get productId() {
    return this.data.get('productId')
  }
}
