import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'field', 'result' ]
  messages = []
  valid = true
  dirty = false

  // connect() {
  //   console.log(`${this.required} ${this.minimum} ${this.maximum}`)
  // }

  validate(event) {
    if (event !== undefined && event.type === 'keyup') {
      this.dirty = true
    }

    if (this.valid && event !== undefined && event.type === 'keyup' || this.dirty === false) {
      return
    }

    this.messages = []
    this.valid = true

    if (this.conditions !== null && !eval(this.conditions)) {
      return
    }

    if (this.required) {
      this.checkRequiredValidation()
    }

    if (typeof this.uniqueness !== 'undefined') {
      this.checkUniquenessValidation()
    }

    if (this.minimum > 0) {
      this.checkMinimumValuesValidation()
    }

    if (this.format !== undefined) {
      this.checkFormat()
    }

    if (this.maximum > 0) {
      this.checkMaximumValuesValidation()
    }

    this.showMessages()
  }

  showMessages() {
    if (this.valid === false) {
      this.resultTarget.innerHTML = `${this.label} ${this.messages.join(', ')}.`
      this.resultTarget.classList.add('invalid-feedback')
    } else {
      this.resultTarget.innerHTML = ''
      this.resultTarget.classList.remove('invalid-feedback')
    }
  }

  checkFormat() {
    var result = this.fieldTarget.value.match(this.format)

    if (result === null) {

      if (this.errorMessages && this.errorMessages['format']) {
        this.messages.push(this.errorMessages['format'])
      } else {
        this.messages.push('format geçersiz')
      }

      this.valid = false
    }
  }

  checkRequiredValidation() {
    if (this.fieldTarget.value === '') {
      if (this.errorMessages && this.errorMessages['required']) {
        this.messages.push(this.errorMessages['required'])
      } else {
        this.messages.push(I18n.validations.cant_be_blank)
      }
      this.valid = false
    }

    if (this.fieldTarget.type === 'select-one') {
      if (this.fieldTarget.children[this.fieldTarget.selectedIndex].getAttribute('value') === null) {

        if (this.errorMessages && this.errorMessages['required']) {
          this.messages.push(this.errorMessages['required'])
        } else {
          this.messages.push('bir seçim yapılmalı')
        }

        this.valid = false
      }
    }

    if (this.fieldTarget.type === 'checkbox') {
      if (this.fieldTargets.map(r => r.checked).filter(v => v === true).length === 0) {

        if (this.errorMessages && this.errorMessages['required']) {
          this.messages.push(this.errorMessages['required'])
        } else {
          this.messages.push('kabul edilmeli')
        }

        this.valid = false
      }
    }
  }

  checkMinimumValuesValidation() {
    // Text tipindeki alanların minimum değeri
    if (this.fieldTarget.type === 'text' || this.fieldTarget.type === 'number' || this.fieldTarget.type === 'email' || this.fieldTarget.type === 'password') {
      if (this.fieldTarget.value.length < this.minimum) {

        if (this.errorMessages && this.errorMessages['minimum']) {
          this.messages.push(this.errorMessages['minimum'])
        } else {
          // this.messages.push(`${I18n.validations.minimum}: ${this.minimum}`)
          this.messages.push(`en az ${this.minimum} karakter olmalı`)
        }

        this.valid = false
      }
    } else if(this.fieldTarget.type === 'checkbox' || this.fieldTarget.type === 'radio' || this.fieldTarget.dataset.type === 'button') {
      if(this.fieldTargets.map(r => r.checked).filter(v => v === true).length < this.minimum) {
        if (this.errorMessages && this.errorMessages['minimum']) {
          this.messages.push(this.errorMessages['minimum'])
        } else {
          // this.messages.push(`${I18n.validations.minimum}: ${this.minimum}`)
          this.messages.push(`en az ${this.minimum} seçeneği işaretlemelisiniz`)
        }

        this.valid = false
      }
    } else {
      if(parseFloat(this.fieldTarget.textContent) < this.minimum) {

        if (this.errorMessages && this.errorMessages['minimum']) {
          this.messages.push(this.errorMessages['minimum'])
        } else {
          // this.messages.push(`${I18n.validations.minimum}: ${this.minimum} ${this.unit}`)
          this.messages.push(`en az ${this.minimum} ${this.unit} seçmelisiniz`)
        }

        this.valid = false
      }
    }
  }

  checkMaximumValuesValidation() {
    // Text tipindeki alanların maximum değeri
    if (this.fieldTarget.type === 'text' || this.fieldTarget.type === 'textarea' || this.fieldTarget.type === 'number' || this.fieldTarget.type === 'email' || this.fieldTarget.type === 'password') {
      if (this.fieldTarget.value.length > this.maximum) {

        if (this.errorMessages && this.errorMessages['maximum']) {
          this.messages.push(this.errorMessages['maximum'])
        } else {
          // this.messages.push(`${I18n.validations.maximum}: ${this.maximum}`)
          this.messages.push(`en fazla ${this.maximum} karakter olmalı`)
        }

        this.valid = false
      }
    } else if(this.fieldTarget.type === 'checkbox' || this.fieldTarget.type == 'radio') {
      if(this.fieldTargets.map(r => r.checked).filter(v => v === true).length > this.maximum) {

        if (this.errorMessages && this.errorMessages['maximum']) {
          this.messages.push(this.errorMessages['maximum'])
        } else {
          // this.messages.push(`${I18n.validations.maximum}: ${this.maximum}`)
          this.messages.push(`en fazla ${this.maximum} seçeneği işaretleyebilirsiniz`)
        }

        this.valid = false
      }
    } else {
      if(parseFloat(this.fieldTarget.textContent) > this.maximum) {

        if (this.errorMessages && this.errorMessages['maximum']) {
          this.messages.push(this.errorMessages['maximum'])
        } else {
          // this.messages.push(`${I18n.validations.maximum}: ${this.maximum} ${this.unit}`)
          this.messages.push(`en fazla ${this.maximum} ${this.unit} seçmelisiniz`)
        }

        this.valid = false
      }
    }
  }

  checkUniquenessValidation() {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content
    const body = { email: this.fieldTarget.value }

    fetch('/email_validation', {
      method: 'POST',
      headers: {
        'X-CSRF-TOKEN': csrfToken,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(data => {
      if (data.valid === false) {
        this.valid = false

        if (this.errorMessages && this.errorMessages['uniqueness']) {
          this.messages.push(this.errorMessages['uniqueness'])
        } else {
          this.messages.push(I18n.validations.uniqueness)
        }

      }

      this.showMessages()
    })
  }

  get rules() {
    return JSON.parse(this.data.get('rules'))
  }

  get errorMessages() {
    return JSON.parse(this.data.get('error-messages'))
  }

  get required() {
    return this.rules.required
  }

  get uniqueness() {
    return this.rules.uniqueness
  }

  get minimum() {
    return parseFloat(this.rules.minimum)
  }

  get maximum() {
    return parseFloat(this.rules.maximum)
  }

  get format() {
    return this.rules.format
  }

  get unit() {
    return this.data.get('unit')
  }

  get conditions() {
    return this.data.get('conditions')
  }

  get label() {
    return this.data.get('label')
  }
}
