import { Controller } from 'stimulus'
export default class extends Controller {

  refund(event) {
    event.preventDefault()

    const swalWithTailwind = swal.mixin({
      customClass: {
        confirmButton: 'button button--primary block w-full',
        cancelButton: 'bg-gray-600 bg-gray-500 text-white px-3 py-2 mx-1 rounded w-32',
        popup: 'max-w-xs text-sm',
        closeButton: 'text-2xl focus:outline-none'
      },
      buttonsStyling: false
    })

    const title = event.currentTarget.dataset.confirmTitle
    const confirmButtonText = event.currentTarget.dataset.confirmButton

    swalWithTailwind.fire({
      title: title || 'Emin misin?',
      html: 'İade talebini onaylıyor musun?',
      // imageUrl: MaskotImage,
      showCancelButton: false,
      confirmButtonText: confirmButtonText || 'Tamam',
      cancelButtonText: 'İptal',
      reverseButtons: true,
      showCloseButton: true
    }).then((result) => {
      if (result.value) {
        fetch(`/orders/${this.id}/refund`, {
          method: 'PUT'
        })
        .then(response => response.text())
        .then(data => {
          this.element.outerHTML = data
        })
      }
    })
  }

  get id() {
    return this.data.get('id')
  }
}
