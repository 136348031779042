import { Controller } from 'stimulus'
import IMask from 'imask'

export default class extends Controller {
  static targets = [ 'field' ]

  connect() {

  }

  masked() {
    var maskOptions = { mask: this.format, lazy: false, autofix: true }
    IMask(this.fieldTarget, maskOptions)

    if (this.fieldTarget.createTextRange) {
      var part = this.fieldTarget.createTextRange();
      part.move("character", 0);
      part.select();
    } else if (this.fieldTarget.setSelectionRange) {
      this.fieldTarget.setSelectionRange(0, 0);
    }
  }

  get format() {
    return this.data.get('format')
  }
}
