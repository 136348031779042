import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['amount']

  connect() {
    this.amountTarget.value = this.amount
  }

  stepUp(event) {
    // if (this.amount >= this.maximum) {
    //   return
    // }
    //
    // this.amount = this.amount + this.increaseAmount
    // this.amountTarget.value = this.amount
    this.amountTarget.stepUp()
  }

  stepDown(event) {
    // if (this.amount <= this.minimum) {
    //   return
    // }
    //
    // this.amount = this.amount - this.increaseAmount
    // this.amountTarget.value = this.amount
    this.amountTarget.stepDown()
  }

  toggle(event) {
    if (parseInt(this.amountTarget.value) === 0) {
      this.amountTarget.value = 1
    } else if (parseInt(this.amountTarget.value) === 1) {
      this.amountTarget.value = 0
    }

    this.preview(event)
  }

  select(event) {
    var valueId;

    if (event.currentTarget.value === undefined) {
      valueId = parseInt(event.currentTarget.dataset.value);
    } else {
      valueId = parseInt(event.currentTarget.value);
    }

    if (Number.isInteger(valueId) && valueId > 0) {
      this.amountTarget.value = 1
    } else {
      this.amountTarget.value = 0
    }

    this.preview(event)
  }

  preview(event) {
    if(this.orderSummaryController !== undefined) {
      this.orderSummaryController.preview(event)
    }
  }

  get orderSummaryController() {
    return this.application.controllers.find(c => c.context.identifier === 'checkout--order-summary')
  }

  // Getters
  get amount() {
    return parseFloat(this.data.get('amount'))
  }

  get increaseAmount() {
    return parseFloat(this.data.get('increase-amount'))
  }

  get minimum() {
    return parseFloat(this.data.get('minimum'))
  }

  get maximum() {
    return parseFloat(this.data.get('maximum'))
  }

  get price() {
    return parseFloat(this.data.get('price'))
  }

  // get variantsController() {
  //   return this.application.getControllerForElementAndIdentifier(this.context.parentElement.parentElement, 'checkout--variants')
  // }

  // Setters
  set amount(value) {
    this.data.set('amount', value)
  }
}
