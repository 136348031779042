import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['type', 'tradeChamber', 'tradeChamberNumber']

  toggleTradeChamberFields() {
    var tradeChamberValidations = JSON.parse(this.tradeChamberTarget.dataset.validationsRules)
    var tradeChamberNumberValidations = JSON.parse(this.tradeChamberNumberTarget.dataset.validationsRules)

    if(this.typeTarget.value === 'individual') {
      this.tradeChamberTarget.classList.add('hidden')
      this.tradeChamberNumberTarget.classList.add('hidden')

      tradeChamberValidations.required = false
      tradeChamberNumberValidations.required = false
    } else if (this.typeTarget.value === 'private_company') {
      this.tradeChamberTarget.classList.remove('hidden')
      this.tradeChamberNumberTarget.classList.remove('hidden')

      tradeChamberValidations.required = false
      tradeChamberNumberValidations.required = false
    } else {
      this.tradeChamberTarget.classList.remove('hidden')
      this.tradeChamberNumberTarget.classList.remove('hidden')

      tradeChamberValidations.required = true
      tradeChamberNumberValidations.required = true
    }

    this.tradeChamberTarget.dataset.validationsRules = JSON.stringify(tradeChamberValidations)
    this.tradeChamberNumberTarget.dataset.validationsRules = JSON.stringify(tradeChamberNumberValidations)
  }
}
