import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['regions', 'districts']

  listDistricts(event) {
    while (this.districtsTarget.options.length > 0) {
      this.districtsTarget.options.remove(0)
    }

    if (event.currentTarget.value !== '') {
      var params = `city_id=${event.currentTarget.value}`;

      if (this.productId !== undefined && this.productId !== '' && this.productId !== null) {
        params += `&v2_product_id=${this.productId}`
      }

      if (this.type !== undefined && this.type !== '' && this.productId !== null) {
        params += `&type=${this.type}`
      }

      fetch(`${this.hostname}/checkout/districts?${params}`)
        .then(response => response.text())
        .then(data => {
          return JSON.parse(data)
        })
        .then(result => {
          var option = document.createElement('option')
          option.value = ''
          option.textContent = 'İlçe'
          this.districtsTarget.appendChild(option)

          for (var i = 0; i < result['districts'].length; i++) {
            var option = document.createElement('option')
            option.value = result['districts'][i]
            option.textContent = result['districts'][i]
            this.districtsTarget.appendChild(option)
          }
        })
    }
  }

  get productId() {
    return this.data.get('productId')
  }

  get type() {
    return this.data.get('type')
  }

  get hostname() {
    const splittedHostname = window.location.host.split('.');
    const lastPart = splittedHostname[splittedHostname.length - 1];

    if (window.location.host === 'merchant.aydanaya.com' || window.location.host === 'merchant.startsub.com' || window.location.host === 'admin.startsub.com') {
      return 'https://abonelik.aydanaya.com';
    }
    else if (window.location.host === 'merchant.aydanaya.dev' || window.location.host === 'merchant.startsub.dev' || window.location.host === 'admin.startsub.dev') {
      return 'https://abonelik.aydanaya.dev';
    } else if (window.location.host === 'merchant.aydanaya.local:3000' || window.location.host === 'merchant.startsub.local:3000' || window.location.host === 'admin.startsub.local:3000') {
      return 'http://abonelik.aydanaya.local:3000';
    } else {
      return '';
    }
  }
}
