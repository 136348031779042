import { Turbo, cable } from '@hotwired/turbo-rails';
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['nextCheckoutDateContainer', 'nextCheckoutDate', 'postponeButton', 'cancelButton', 'pauseButton']

  get id() {
    return this.data.get('id')
  }

  get email() {
    return this.data.get('email')
  }

  get fullName() {
    return this.data.get('fullName')
  }

  get phone() {
    return this.data.get('phone')
  }
}
