import { Turbo, cable } from "@hotwired/turbo-rails";
import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import "../stylesheets/application.scss";
import "@fortawesome/fontawesome-free/js/all";
import "lazysizes";

Rails.start();
ActiveStorage.start();
const application = Application.start();
const context = require.context("controllers", true, /_controller\.js$/);
const context_components = require.context("../../components", true, /_controller.js$/);
application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(context_components)
  )
);
