import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['showPaymentFormTrigger', 'hidePaymentFormTrigger',
                    'selectors', 'forms', 'paymentForm', 'showPaymentForm']

  connect() {
    this.checkPaymentForm()
  }

  togglePaymentForm() {
    this.showPaymentForm = !(this.showPaymentForm == 'true')
    this.checkPaymentForm()
  }

  checkPaymentForm() {
    if (this.showPaymentForm == 'false') {
      this.selectorsTarget.classList.remove('hidden')
      this.formsTarget.classList.add('hidden')

      this.showPaymentFormTriggerTarget.classList.remove('hidden')
      this.hidePaymentFormTriggerTarget.classList.add('hidden')
    } else {
      this.selectorsTarget.classList.add('hidden')
      this.formsTarget.classList.remove('hidden')

      this.showPaymentFormTriggerTarget.classList.add('hidden')
      this.hidePaymentFormTriggerTarget.classList.remove('hidden')
    }
  }

  preview(event) {
    if(this.form !== null) {
      this.form.action = `/checkout/preview/${this.productId}`;
      // this.submitForm();

      Rails.fire(this.form, 'submit')

      this.form.action = '/subscriptions'
    }
  }

  // submitForm() {
  //   const e = new CustomEvent('submit', { bubbles: true });
  //   this.form.dispatchEvent(e);
  // }

  get form() {
    let el = document.querySelector('#payment > form')

    return el
  }

  get productId() {
    return this.data.get('productId')
  }

  get showPaymentForm() {
    return this.data.get('showPaymentForm')
  }

  set showPaymentForm(value) {
    this.data.set('showPaymentForm', value)
    this.showPaymentFormTarget.value = value
  }
}
