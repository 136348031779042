import { Controller } from 'stimulus'

export default class extends Controller {
  valid = false

  validate(event) {
    this.validationsControllers.map((c) => c.dirty = true)
    this.validationsControllers.map((c) => c.validate())
    this.valid = !this.validationsControllers.map((c) => c.valid).includes(false)

    if (!this.valid) {
      event.preventDefault()
      this.scrollToError(event)
    }
  }

  scrollToError(event) {
    var errorElements = document.getElementsByClassName('invalid-feedback')

    for (var i = 0; i < errorElements.length; i++) {
      if (errorElements[i].textContent !== '') {
        window.scroll({ top: errorElements[i].getBoundingClientRect().top + window.pageYOffset - 175, left: 0, behavior: 'smooth' })
        break
      }
    }
  }

  get validationsControllers() {
    return this.application.controllers.filter(c => c.identifier === 'validations')
  }
}
